import { LogFactory } from '../../src-ts/common/Log';
import { App } from './App';
import { JoinCode, joinCodeIsValid } from '../../src-ts/common/messages/JoinCode';
import { GameSession, JoinGameParams } from './GameSession';
import { ControllerActionButton } from './ControllerActionButton';
import { ImageURLCache } from './ImageURLCache';
import { ItemManagementData } from './ItemManagementData';
import { CharacterWritingData, WriterInputMinLength } from './CharacterWritingData';

export enum CharacterButtonState {
	SelectedByThis,
	SelectedByOther,
	Unselected,
}

export class ViewManager {
	constructor() {
		// Record all the views
		document.querySelectorAll<HTMLElement>('.view').forEach((v) => (this.views[v.id] = v));

		addEventListener('resize', this.onWindowResized);

		this.setupWheelScrollsHorizontallyElements();
	}

	private currentViewId?: View;

	/** Used to stash the previous View in case asked to restore. */
	private previousViewId?: View;

	private currentStack?: string; // undefined when not set
	private currentStackPhase: number = -1; // -1 when not set
	/**
	 * Dictionary of stackName to array of HTMLElement phases.
	 * @private
	 */
	private readonly stacks: { [index: string]: HTMLElement[] } = {};
	/**
	 * Dictionary of view name to HTMLElement.
	 * @private
	 */
	private readonly views: { [index: string]: HTMLElement } = {};

	private infoPopupResult: string = '';

	private static readonly ScrollElementPadding: number = 2;

	public getCurrentViewId = (): string => {
		return this.currentViewId ? this.currentViewId : '';
	};

	public hideAll = (): void => {
		Log.debug('hideAll');
		for (const key in this.views) {
			this.views[key].style.display = 'none';
		}
	};

	public show = (id: View, forceRefresh: boolean = false): void => {
		if (!forceRefresh && this.currentViewId === id) {
			Log.warn(`Already showing View:`, id);
			return;
		}
		const view = this.views[id];
		if (view) {
			// Stash previous in case need to restore
			this.previousViewId = this.currentViewId;
			this.currentViewId = id;
			this.hideAll();
			Log.debug('show id', id, '= view', view);
			view.style.display = 'block';

			this.onShowingView(id);
		} else {
			Log.warn('Could not find view with ID:', id);
		}
	};

	private onShowingView = (id: View): void => {
		if (View.Lobby === id) {
			// Show logo header in lobby only
			this.setElementShowing('lobbyHeader', true);
			const joinCodeInputField = this.getElementByIdExpectNonNull('joinCode') as HTMLInputElement;

			const queryString = window.location.search;
			const urlParams = new URLSearchParams(queryString);
			const joinCodeParam = urlParams.get('joinCode');
			if (joinCodeParam !== null && joinCodeIsValid(joinCodeParam)) {
				joinCodeInputField.value = joinCodeParam;
			}
		} else {
			this.setElementShowing('lobbyHeader', false);
		}

		// Show player tabs (for switching between GameSessions) in certain views
		const showPlayerTabs =
			View.CharacterSelect === id ||
			View.GameInputPhase === id ||
			View.ItemPlacementVote === id ||
			View.WriterTextInput === id;
		this.setElementShowing('playerTabsAndMenu', showPlayerTabs);

		this.updatePlayerNameLayout();

		this.setDefaultFocusForView(id);

		// Get URL search parameters
		const queryString = window.location.search;
		const urlParams = new URLSearchParams(queryString);
		// Push browser history states for back button behaviour, retaining current URL parameters (e.g. joinCode)
		urlParams.delete('view');
		urlParams.append('view', 'ConfirmLeave');
		history.pushState(id, '', `./?${urlParams.toString()}`);
		urlParams.delete('view');
		urlParams.append('view', id);
		history.pushState(id, '', `./?${urlParams.toString()}`);
	};

	public restorePreviousView = (): void => {
		if (!this.previousViewId) {
			Log.warn('No previousViewId to restore');
			return;
		}

		this.show(this.previousViewId);
	};

	private onWindowResized = (event: UIEvent): void => {
		this.updatePlayerNameLayout();
	};

	private setupWheelScrollsHorizontallyElements = (): void => {
		// For any elements with the class 'wheelScrollsHorizontally', the scroll wheel will
		// adjust their horizontal scroll rather than trying to scroll vertically as is standard
		const horizontalScrollElements = document.getElementsByClassName('wheelScrollsHorizontally');
		for (var i = 0; i < horizontalScrollElements.length; ++i) {
			const horizontalScrollElement = horizontalScrollElements[i] as HTMLElement;
			horizontalScrollElement.addEventListener('wheel', function (e) {
				if (e.deltaX !== 0) {
					// Scrolling horizontally, continue as normal
					return;
				}
				if (e.deltaY !== 0) {
					// Add vertical scroll delta to horizontal scroll instead
					horizontalScrollElement.scrollLeft += e.deltaY;
					e.preventDefault();
				}
			});
		}
	};

	public showGameMenu = (): void => {
		// Push empty history state to prevent further back presses
		history.pushState(null, '', null);

		this.getElementByIdExpectNonNull('gameMenu').style.display = 'block';
	};

	private updatePlayerNameLayout = (): void => {
		let playerNameTop = this.getElementByIdExpectNonNull('playerNameTop');
		let playerNameOverflow = this.getElementByIdExpectNonNull('playerNameOverflowPanel');

		let minPageSize = Math.min(document.documentElement.clientWidth, document.documentElement.clientHeight);
		let vminWidth: number = (playerNameTop.clientWidth / minPageSize) * 100;

		if (vminWidth === 0) {
			return;
		}

		// If width > 65, there is enough space to display the player name alongside tabs at the top.
		//	Otherwise, it should be displayed in a separate 'overflow' panel underneath the tabs.
		let displayAtTop = vminWidth > 65;
		playerNameTop.style.visibility = displayAtTop ? 'visible' : 'hidden';
		playerNameOverflow.style.visibility = displayAtTop ? 'hidden' : 'visible';
	};

	private setDefaultFocusForView = (id: View): void => {
		let focusElement: HTMLElement | null = null;
		switch (id) {
			case View.Lobby:
				// Autofocus on join code field in lobby, or player name if join code field is hidden/filled
				const joinCodeInputField = this.getElementByIdExpectNonNull('joinCode') as HTMLInputElement;
				if ('none' === joinCodeInputField.style.display || joinCodeIsValid(joinCodeInputField.value)) {
					focusElement = this.getElementByIdExpectNonNull('playerName');
				} else {
					focusElement = joinCodeInputField;
				}
				break;
			case View.Default:
			case View.Connecting:
			case View.WaitOrAddPlayers:
			case View.CharacterSelect:
			case View.LookAtScreen:
			case View.GameInputPhase:
			case View.ItemPlacementVote:
			case View.Reconnecting:
			case View.WriterTextInput:
				// nop
				return;
			default:
				throw new Error(`Unexpected view id for setDefaultFocusForView: ${id}`);
		}

		if (null === focusElement) {
			Log.error(`Null focusElement in setDefaultFocusForView for view id: ${id}`);
			return;
		}
		focusElement!.focus();
	};

	public showConfirmLeaveDialog = (): void => {
		// Push empty history state to prevent further back presses
		history.pushState(null, '', null);

		// Show leave confirmation dialog
		const confirmLeave = this.getElementByIdExpectNonNull('confirmLeaveGame');
		confirmLeave.style.display = 'block';
	};

	public showInfoPopupDialog = (
		titleText: string,
		infoText: string,
		onButton1Pressed: Function | null,
		button1Text: string = 'Close',
		canShowDetailedInfo: boolean = false,
		detailedInfoText: string = ''
	): Promise<void> => {
		return new Promise((resolve) => {
			Log.log(`Showing info popup dialog: "${infoText}", details:"${detailedInfoText}"`);

			// Push empty history state to prevent further back presses
			history.pushState(null, '', null);

			const popupElement = this.getElementByIdExpectNonNull('infoPopup');
			popupElement.style.display = 'block';

			const titleTextElement = this.getElementByIdExpectNonNull('popupTitleText');
			// Title element is hidden if no title text was given
			titleTextElement.style.display = titleText === '' ? 'none' : 'block';
			titleTextElement.innerText = titleText;

			const infoTextElement = this.getElementByIdExpectNonNull('popupInfoText');
			infoTextElement.innerText = infoText;

			const buttonText = this.getElementByIdExpectNonNull('errorPopupButton1');
			buttonText.innerText = button1Text;

			// if canShowDetailedInfo: allow player to press a button that shows detailText
			this.setElementShowing('detailedErrorButton', canShowDetailedInfo);

			this.infoPopupResult = '';

			const detailText = this.getElementByIdExpectNonNull('detailedErrorText');
			detailText.style.display = 'none';
			detailText.innerText = detailedInfoText;

			// Record global callback which will be triggered from HTML's call to setInfoPopupResult()
			this.infoPopupCallback = () => {
				this.infoPopupCallback = undefined;
				if ('button1' === this.infoPopupResult) {
					if (onButton1Pressed) {
						Log.log('Info popup: Triggering button1 action');
						onButton1Pressed();
					} else {
						Log.log('Info popup: Closing without triggering action');
					}
				}
				popupElement.style.display = 'none';

				// Complete the Promise
				resolve();
			};
		});
	};

	private infoPopupCallback?: () => void;

	// noinspection JSUnusedGlobalSymbols -- used from HTML
	public setInfoPopupResult = (result: string): void => {
		Log.log(`setInfoPopupResult: "${result}"`);
		this.infoPopupResult = result;
		if (!this.infoPopupCallback) {
			throw new Error(`No infoPopupCallback defined in setInfoPopupResult(${result})`);
		}

		this.infoPopupCallback();
	};

	/** A named stack of 'screens' in order to be able to show them behind one another */
	public addStackWithPhases = (stackName: string, phaseIds: string[]): void => {
		const newStack = []; // initialize new array
		for (let i = 0; i < phaseIds.length; i++) {
			let view = this.views[phaseIds[i]];
			if (null == view) {
				Log.warn('Could not find view phase with ID:', phaseIds[i]);
			} else {
				newStack.push(view);
			}
		}
		this.stacks[stackName] = newStack; // initialize new array
	};

	public showStackPhase = (stackName: string, phaseNumToShow: number): void => {
		const stack = this.stacks[stackName];
		if (null == stack) {
			Log.error(`Stack "${stackName}" not found in `, this.stacks);
			return;
		}
		for (let i = 0; i <= phaseNumToShow; i++) {
			stack[i].style.display = 'block';
		}
		for (let i = phaseNumToShow + 1; i < stack.length; i++) {
			stack[i].style.display = 'none';
		}

		this.currentStack = stackName;
		this.currentStackPhase = phaseNumToShow;
	};

	public popStackPhase = (): void => {
		Log.log("popping currentStack:'" + this.currentStack + "' with currentStackPhase:'" + this.currentStackPhase + "'");
		if (null == this.currentStack || 0 > this.currentStackPhase) {
			Log.error("No currentStack:'" + this.currentStack + "' or currentStackPhase:'" + this.currentStackPhase + "'");
			return;
		}
		if (0 === this.currentStackPhase) {
			this.show(View.GameInputPhase);
			this.currentStack = undefined;
			this.currentStackPhase = -1;
			return;
		}
		this.showStackPhase(this.currentStack, this.currentStackPhase - 1);
	};

	public setupLobbyUI = (isInitialSession: boolean): void => {
		Log.log(`Setting up lobby (isInitialSession: ${isInitialSession})`);

		// Only show join code title/input field if the first player is joining
		//	(All subsequent players should be joining the same game, and hence should not change the join code)
		this.setElementShowing('joinCodeTitle', isInitialSession);
		this.setElementShowing('joinCode', isInitialSession);

		// If this isn't the first player joining, show button that allows them to cancel joining
		this.setElementShowing('cancelJoinButton', !isInitialSession);

		const nameTitle = this.getElementByIdExpectNonNull('nameTitleText');

		// Clarify name is for 'New Player' when adding more than one on a single device
		nameTitle.innerText = isInitialSession ? 'Player Name' : `Name of New Player`;

		const joinCodeField: HTMLInputElement = document.getElementById('joinCode') as HTMLInputElement;
		if (!joinCodeField) {
			throw new Error('Could not find joinCode input field when setting up lobby UI');
		}

		const playerNameField: HTMLInputElement = document.getElementById('playerName') as HTMLInputElement;
		if (!playerNameField) {
			throw new Error('Could not find joinCode input field when setting up lobby UI');
		}

		// Reset input field text values
		joinCodeField.value = '';
		playerNameField.value = '';
	};

	public setupJoinedPlayersList = (playerNames: string[], sessionCount: number): void => {
		this.setElementShowing('addPlayerInfo', sessionCount < App.MaxGameSessions, 'flex');

		const playerNamesList = this.getElementByIdExpectNonNull('playerNamesList');

		// Remove all but the first child from the list of player names
		//	(the first child is playerNameListElement which we want to clone)
		for (let i = playerNamesList.childNodes.length - 1; i > 1; i--) {
			playerNamesList.childNodes[i].remove();
		}

		// playerNameListElement is hidden and used as a template to clone from
		const playerNameElement = this.getElementByIdExpectNonNull('playerNameListElement');

		// Add a new playerNameElement clone for each player name,
		//	each will display the name along with a button for removing that player
		playerNames.forEach((name, index) => {
			const clone = playerNameElement.cloneNode(true) as HTMLElement;
			clone.id = `${clone.id}${index}`;
			clone.style.display = 'flex';
			playerNamesList.appendChild(clone);

			// Show name
			const nameText = clone.querySelector<HTMLElement>('.playerNameText');
			if (!nameText) throw new Error('Failed to get playerNameText from cloned playerNameListElement');
			nameText.innerText = name;

			// Set button tab index to allow tab/enter key selection
			const removeButton = clone.querySelector<HTMLElement>('.pressEffectButton');
			if (!removeButton) throw new Error('Failed to get pressEffectButton from cloned playerNameListElement');
			removeButton.tabIndex = 100 + index;
		});
	};

	public setJoinCodeText = (newJoinCode: string): void => {
		const joinCodeText = this.getElementByIdExpectNonNull('joinCodeText');
		joinCodeText.innerText = newJoinCode;
	};

	public setTurnInfoHeaderText = (turnNum: number): void => {
		const turnInfoHeader = this.getElementByIdExpectNonNull('turnInfoHeader');
		turnInfoHeader.innerText = `Choose an action for turn ${turnNum + 1}`;
	};

	public setReadyButtonShowing = (show: boolean): void => {
		const readyButton = this.getElementByIdExpectNonNull('readyButton');
		readyButton.style.visibility = show ? 'visible' : 'hidden';
	};

	public positionReadyUnreadyButtonsForGameInput = (): void => {
		Log.debug('positionReadyUnreadyButtonsForGameInput');
		const readyButton = this.getElementByIdExpectNonNull('readyButton');
		readyButton.classList.remove('readyButtonItemVote');
		readyButton.classList.remove('readyButtonWriterTextInput');
		readyButton.classList.add('readyButtonActionInput');
		const unreadyButton = this.getElementByIdExpectNonNull('unreadyButton');
		unreadyButton.classList.remove('unreadyButtonItemVote');
		unreadyButton.classList.remove('unreadyButtonWriterTextInput');
		unreadyButton.classList.add('unreadyButtonActionInput');

		this.getElementByIdExpectNonNull('readyButton_gameInput').appendChild(readyButton);
		this.getElementByIdExpectNonNull('unreadyButton_gameInput').appendChild(unreadyButton);
		readyButton.innerText = 'Ready!';
	};
	public positionReadyUnreadyButtonsForItemVote = (): void => {
		Log.debug('positionReadyUnreadyButtonsForItemVote');
		const readyButton = this.getElementByIdExpectNonNull('readyButton');
		readyButton.classList.remove('readyButtonActionInput');
		readyButton.classList.remove('readyButtonWriterTextInput');
		readyButton.classList.add('readyButtonItemVote');
		const unreadyButton = this.getElementByIdExpectNonNull('unreadyButton');
		unreadyButton.classList.remove('unreadyButtonActionInput');
		unreadyButton.classList.remove('unreadyButtonWriterTextInput');
		unreadyButton.classList.add('unreadyButtonItemVote');

		this.getElementByIdExpectNonNull('readyButton_itemVote').appendChild(readyButton);
		this.getElementByIdExpectNonNull('unreadyButton_itemVote').appendChild(unreadyButton);
		readyButton.innerText = 'Ready!';
	};
	public positionReadyUnreadyButtonsForWriterTextInput = (): void => {
		Log.debug('positionReadyUnreadyButtonsForWriterTextInput');
		const readyButton = this.getElementByIdExpectNonNull('readyButton');
		readyButton.classList.remove('readyButtonActionInput');
		readyButton.classList.remove('readyButtonItemVote');
		readyButton.classList.add('readyButtonWriterTextInput');
		const unreadyButton = this.getElementByIdExpectNonNull('unreadyButton');
		unreadyButton.classList.remove('unreadyButtonActionInput');
		unreadyButton.classList.remove('unreadyButtonItemVote');
		unreadyButton.classList.add('unreadyButtonWriterTextInput');

		this.getElementByIdExpectNonNull('readyButton_writerTextInput').appendChild(readyButton);
		this.getElementByIdExpectNonNull('unreadyButton_writerTextInput').appendChild(unreadyButton);
		readyButton.innerText = 'Send';
	};

	public setReadyButtonPressed = (pressed: boolean, allowUnready: boolean = true): void => {
		const readyButton = this.getElementByIdExpectNonNull('readyButton');

		Log.debug(
			'setReadyButtonPressed(pressed:',
			pressed,
			', allowUnready:',
			allowUnready,
			') on readyButton:',
			readyButton
		);

		// Show the 'unready' button when ready is pressed
		const unreadyButton = this.getElementByIdExpectNonNull('unreadyButton');
		unreadyButton.style.visibility = pressed && allowUnready ? 'visible' : 'hidden';
		if (pressed) {
			readyButton.classList.add('readyButtonPressed');
			unreadyButton.classList.add('fadeInAnimated');
		} else {
			readyButton.classList.remove('readyButtonPressed');
			unreadyButton.classList.remove('fadeInAnimated');
		}
	};

	public setActionButtonEnabled = (buttonNumber: number, actionButtonData: ControllerActionButton): void => {
		const actionButtonElement = this.getElementByIdExpectNonNull(`actionButton${buttonNumber}`);
		const actionButtonInnerElement = this.getElementByIdExpectNonNull(`actionButtonInner${buttonNumber}`);
		const actionButtonImageElement = this.getElementByIdExpectNonNull(`actionButtonImage${buttonNumber}`);
		const actionLabelElement = this.getElementByIdExpectNonNull(`actionLabel${buttonNumber}`);

		if (actionButtonData.enabledOrDefault) {
			actionButtonElement.tabIndex = 105 + buttonNumber;
			actionButtonElement.classList.remove('disabledButton');

			actionButtonImageElement.classList.remove('lockedImage');

			actionLabelElement.innerText = actionButtonData.labelOrDefault;
		} else {
			actionButtonElement.tabIndex = -1;
			actionButtonElement.classList.add('disabledButton');
			actionButtonElement.style.backgroundColor = '#8a8a8a';

			actionButtonInnerElement.style.backgroundColor = '#e7e7e7';
			actionButtonImageElement.classList.add('lockedImage');

			actionLabelElement.innerText = '[?]';
		}
	};

	public updateTurnSelectedVisuals = (currentTurn: number, numberOfTurns: number): void => {
		for (let i = 0; i < numberOfTurns; i++) {
			const turnButtonElement = this.getElementByIdExpectNonNull(`turnButton${i}`);
			turnButtonElement.className = i === currentTurn ? 'actionButton actionButtonSelected' : 'actionButton';
		}
		const actionsHeadingElement = this.getElementByIdExpectNonNull('actionsHeadingText');
		if (numberOfTurns <= 1) {
			actionsHeadingElement.innerText = 'This turn, I will...';
		} else {
			actionsHeadingElement.innerText = `On turn ${(currentTurn + 1).toString()}, I will...`;
		}
	};

	public updateActionSelectedVisuals = (currentAction: number): void => {
		for (let i = 0; i < App.ActionButtonCount; i++) {
			const actionButtonElement = this.getElementByIdExpectNonNull(`actionButton${i}`);
			if (i === currentAction) {
				actionButtonElement.classList.add('actionButtonSelected');
			} else {
				actionButtonElement.classList.remove('actionButtonSelected');
			}
		}
	};

	public updateDirectionSelectedVisuals = (selectedDirection: string): void => {
		for (let i = 0; i < App.AllDirectionNames.length; i++) {
			const directionName = App.AllDirectionNames[i];
			const directionButtonElement = this.getElementByIdExpectNonNull(`${directionName}DirectionalButton`);
			directionButtonElement.className =
				directionName === selectedDirection ? 'directionalButton directionalButtonSelected' : 'directionalButton';
		}
	};

	public setupTurnButtons = (numberOfTurns: number): void => {
		const turnHeadingTextElement = this.getElementByIdExpectNonNull('turnHeadingText');
		if (numberOfTurns <= 1) {
			turnHeadingTextElement.innerText = `My chosen action:`;
		} else {
			turnHeadingTextElement.innerText = `My ${numberOfTurns} turns are:`;
		}

		const turnButtonsParent = this.getElementByIdExpectNonNull('turnButtonsPanel');

		// Remove any existing children ready to add new turn buttons
		for (let i = turnButtonsParent.childNodes.length - 1; i >= 0; i--) {
			turnButtonsParent.childNodes[i].remove();
		}

		// Add turn button clone for each turn
		for (let i = 0; i < numberOfTurns; i++) {
			const cloneFrom = this.getElementByIdExpectNonNull('turnButtonTemplate');
			const clone = cloneFrom.cloneNode(true) as HTMLElement;
			clone.id = `turnButton${i}`;
			clone.style.display = 'flex';

			const turnButtonInner = clone.children[0];
			turnButtonInner.id = `turnButtonInner${i}`;
			turnButtonInner.children[0].id = `turnButtonImage${i}`;

			const actionNumberText = clone.children[2] as HTMLElement;
			if (numberOfTurns <= 1) {
				// Button doesn't need to be selectable when there's only 1 turn
				clone.tabIndex = -1;
				clone.style.pointerEvents = 'none';
				// No need to show turn number when there's only 1 turn
				const actionNumberPanel = clone.children[1] as HTMLElement;
				actionNumberPanel.style.display = 'none';
				actionNumberText.innerText = '';
			} else {
				clone.tabIndex = 101 + i;
				actionNumberText.innerText = (i + 1).toString();
			}

			turnButtonsParent.appendChild(clone);
		}
	};

	public setTurnButtonVisual = (
		turnNumber: number,
		turnSelected: boolean,
		actionButtonData: ControllerActionButton | undefined,
		data: string,
		imageURLCache: ImageURLCache
	): void => {
		const turnButtonElement = this.getElementByIdExpectNonNull(`turnButton${turnNumber}`);
		const turnButtonInnerElement = this.getElementByIdExpectNonNull(`turnButtonInner${turnNumber}`);
		const turnImageElement = this.getElementByIdExpectNonNull(`turnButtonImage${turnNumber}`);
		turnImageElement.style.backgroundImage = '';

		if (!turnSelected || actionButtonData === undefined) {
			turnButtonElement.style.backgroundColor = '';
			turnButtonInnerElement.style.backgroundColor = '';
			turnImageElement.className = 'actionButtonImage';
			return;
		}

		turnButtonElement.style.backgroundColor = actionButtonData.mainColourOrDefault;
		turnButtonInnerElement.style.backgroundColor = actionButtonData.secondaryColourOrDefault;

		if (actionButtonData.isCoOperationButton) {
			const actionImageName = this.getImageNameFromAction(actionButtonData.action);
			const dataImageName = data ? data.charAt(0).toUpperCase() + data.slice(1) : '';
			turnImageElement.className = `actionButtonImage animatedIcon ${actionImageName}${dataImageName}Image`;
		} else {
			turnImageElement.className = `actionButtonImage`;
			if (actionButtonData === undefined || actionButtonData.imageName === undefined) {
				return;
			}
			const cachedImageUrl = imageURLCache.tryGet(actionButtonData.imageName);
			turnImageElement.style.backgroundImage = cachedImageUrl.success ? `url('${cachedImageUrl.url}')` : '';
		}
	};

	public setActionButtonVisual = (
		actionNumber: number,
		actionButtonData: ControllerActionButton,
		imageURLCache: ImageURLCache
	): void => {
		const actionButtonElement = this.getElementByIdExpectNonNull(`actionButton${actionNumber}`);
		const actionButtonInnerElement = this.getElementByIdExpectNonNull(`actionButtonInner${actionNumber}`);
		const actionLabelElement = this.getElementByIdExpectNonNull(`actionLabel${actionNumber}`);
		const actionButtonImageElement = this.getElementByIdExpectNonNull(`actionButtonImage${actionNumber}`);

		actionButtonImageElement.style.backgroundImage = '';

		actionButtonElement.style.backgroundColor = actionButtonData.mainColourOrDefault;
		actionButtonInnerElement.style.backgroundColor = actionButtonData.secondaryColourOrDefault;
		actionLabelElement.innerText = actionButtonData.labelOrDefault;

		if (actionButtonData.isCoOperationButton) {
			const actionImageName = this.getImageNameFromAction(actionButtonData.action);
			actionButtonImageElement.className = `actionButtonImage animatedIcon ${actionImageName}Image`;
		} else {
			actionButtonImageElement.className = 'actionButtonImage animatedIcon';
			if (!actionButtonData.imageName) {
				return;
			}
			const cachedImageUrl = imageURLCache.tryGet(actionButtonData.imageName);
			actionButtonImageElement.style.backgroundImage = cachedImageUrl.success ? `url('${cachedImageUrl.url}')` : '';
		}
	};

	public setDirectionalButtonsForAction = (actionButtonData: ControllerActionButton): void => {
		for (let i = 0; i < App.AllDirectionNames.length; i++) {
			const directionName: string = App.AllDirectionNames[i];
			// const directionButtonElement = this.getElementByIdExpectNonNull(`${directionName}DirectionalButton`);
			const directionImageElement = this.getElementByIdExpectNonNull(`${directionName}ArrowImage`);

			const directionNameUpper: string = directionName.charAt(0).toUpperCase() + directionName.slice(1);

			if (actionButtonData.isCoOperationButton) {
				// Default (Co OPERATION) action, change class name based on action to get the related arrow style
				directionImageElement.className = `directionalButtonImage ${actionButtonData.action}${directionNameUpper}Image`;
			} else {
				// For custom actions, we default to using the 'move' directional arrows
				directionImageElement.className = `directionalButtonImage move${directionNameUpper}Image`;
			}
		}
	};

	public setDirectionButtonsEnabled = (enabled: boolean): void => {
		const directionButtonsLayoutElement = this.getElementByIdExpectNonNull('directionButtonsLayout');
		directionButtonsLayoutElement.className = enabled ? '' : 'disabledFade';
	};

	/**
	 * Enable a player tab (optionally setting its label).
	 */
	public setPlayerTabShowing = (tabIndex: number, show: boolean): void => {
		const tabElement = document.getElementById(`playerTabButton${tabIndex}`);
		if (!tabElement) {
			Log.error(`Player tab element not found: playerTabButton${tabIndex}`);
			return;
		}
		if (!tabElement.firstElementChild) throw new Error(`No children under: playerTabButton${tabIndex}`);

		// Use 'tabButton' class for all tabs, plus 'selectedTab' for the one that is now selected
		tabElement.style.display = show ? 'block' : 'none';
	};

	public updatePlayerTabVisuals = (
		activeTabIndex: number,
		activePlayerName: string,
		characterIndexes: number[]
	): void => {
		// When the selected tab changes, the visuals of all tabs are updated to ensure only one is visually selected
		for (let i = 0; i < App.MaxGameSessions; i++) {
			const tabElement = document.getElementById(`playerTabButton${i}`);
			if (!tabElement) {
				throw new Error(`Player tab element not found: playerTabButton${i}`);
			}

			// Use 'tabButton' class for all tabs, plus 'selectedTab' for the one that is now selected
			tabElement.className = i === activeTabIndex ? 'tabButton playerTab selectedTab' : 'tabButton playerTab';

			const playerImageElement = document.getElementById(`playerTabImage${i}`);
			if (!playerImageElement) {
				throw new Error(`Player tab image element not found: playerImageElement${i}`);
			}

			if (i >= characterIndexes.length || characterIndexes[i] < 0) {
				// Negative index or out of range = No character has been selected yet
				playerImageElement.className = `tabPlayerImage characterPendingImage`;
				// playerImageElement.className = `tabPlayerImage player${i + 1}PendingImage`;
			} else {
				const characterNum = characterIndexes[i];
				if (characterNum === undefined) {
					return;
				}
				// A character was selected, show  a 'highlighted' image with that character,
				// or an 'unselected' version for all tabs other than the selected one
				if (characterNum < GameSession.CharacterPlayerCount) {
					playerImageElement.className =
						i === activeTabIndex
							? `tabPlayerImage character${characterIndexes[i] + 1}HighlightedImage`
							: `tabPlayerImage character${characterIndexes[i] + 1}UnselectedImage`;
				} else {
					playerImageElement.className =
						i === activeTabIndex
							? `tabPlayerImage nonCharacterWriterHighlightedImage`
							: `tabPlayerImage nonCharacterWriterUnselectedImage`;
				}
			}
		}

		// Also display the name of the selected player (this is updated in two places, one of which
		//	will be visible at any given time depending on the screen size/orientation)
		const playerNameTopElement = this.getElementByIdExpectNonNull('playerNameTop');
		const playerNameOverflowElement = this.getElementByIdExpectNonNull('playerNameOverflow');

		// Prepend the name that is displayed next to player tabs with a space for better visual spacing
		playerNameTopElement.innerText = `\u00a0${activePlayerName}`;
		playerNameOverflowElement.innerText = activePlayerName;
	};

	public updateSessionReadyStateVisuals = (sessionIndex: number, ready: boolean): void => {
		const readyIndicatorElement = document.getElementById(`readyIndicator${sessionIndex}`);
		if (!readyIndicatorElement) {
			throw new Error(`Could not find ready indicator element 'readyIndicator${sessionIndex}'`);
		}

		readyIndicatorElement.className = ready ? 'tabReadyImage tickImage' : 'tabReadyImage actionsPendingImage';
	};

	public updateCharacterPreviewImage = (characterIndex: number): void => {
		const characterPreviewElement = document.getElementById('characterPreview');
		if (!characterPreviewElement) {
			throw new Error('Could not find characterPreview elememnt');
		}

		characterPreviewElement.className = `characterPreviewImage${characterIndex + 1}`;
		characterPreviewElement.classList.remove('fadeInAnimated');
		characterPreviewElement.style.visibility = 'hidden';
		setTimeout(() => {
			characterPreviewElement.classList.add('fadeInAnimated');
			characterPreviewElement.style.visibility = 'visible';
		}, 10);
	};

	public updateCharacterSelectButtons = (
		buttonStates: CharacterButtonState[],
		nonCharacterSelected: boolean,
		allNonCharactersSelected: boolean
	): void => {
		// Characters
		for (let i = 0; i < GameSession.CharacterPlayerCount; i++) {
			const characterButton = this.getElementByIdExpectNonNull(`characterButton${i}`);
			const icon = this.getElementByIdExpectNonNull(`characterIcon${i}`);
			switch (buttonStates[i]) {
				case CharacterButtonState.SelectedByThis:
					characterButton.className = 'pressEffectButton characterSelectButton selectedButton selectedScale';
					icon.className = `characterSelectButtonImage character${i + 1}SelectedImage`;
					break;
				case CharacterButtonState.SelectedByOther:
					characterButton.className = 'pressEffectButton characterSelectButton buttonOutline unselectedButton';
					icon.className = `characterSelectButtonImage character${i + 1}CrossImage`;
					break;
				case CharacterButtonState.Unselected:
					characterButton.className = 'pressEffectButton characterSelectButton buttonOutline';
					icon.className = `characterSelectButtonImage character${i + 1}UnselectedImage`;
					break;
				default:
					throw new Error(`Unexpected CharacterButtonState for updateCharacterSelectButtons: ${buttonStates[i]}`);
			}
		}
		// Non-characters
		const nonCharacterButton = this.getElementByIdExpectNonNull(`nonCharacterButton0`);
		const nonCharacterIcon = this.getElementByIdExpectNonNull(`nonCharacterIcon0`);
		if (nonCharacterSelected) {
			nonCharacterButton.className =
				'pressEffectButton characterSelectButton nonCharacterButton selectedButton selectedScale';
			nonCharacterIcon.className = 'characterSelectButtonImage nonCharacterButtonImage nonCharacterWriterSelectedImage';
		} else if (allNonCharactersSelected) {
			nonCharacterButton.className =
				'pressEffectButton characterSelectButton nonCharacterButton buttonOutline unselectedButton';
			nonCharacterIcon.className = 'characterSelectButtonImage nonCharacterButtonImage nonCharacterWriterCrossImage';
		} else {
			nonCharacterButton.className = 'pressEffectButton characterSelectButton nonCharacterButton buttonOutline';
			nonCharacterIcon.className =
				'characterSelectButtonImage nonCharacterButtonImage nonCharacterWriterUnselectedImage';
		}
	};

	public updateItemPlacementVoteVisuals = (
		itemManagementData: ItemManagementData | undefined,
		imageUrlCache: ImageURLCache
	): void => {
		if (!itemManagementData) {
			return;
		}
		const selectedItemIndex = itemManagementData.selectedItemVoteIndex;

		// Display selected item name
		const selectedItemText = this.getElementByIdExpectNonNull('selectedItemText');
		selectedItemText.innerText = itemManagementData.selectedItem.itemName;

		// Hide left/right item previews & left/right directional arrow button if looking at the first/last item
		this.getElementByIdExpectNonNull('itemPreviewLeft').style.opacity = selectedItemIndex <= 0 ? '0' : '';
		this.getElementByIdExpectNonNull('itemPreviewRight').style.opacity =
			selectedItemIndex >= itemManagementData.items.length - 1 ? '0' : '';
		this.getElementByIdExpectNonNull('itemVoteLeftButton').style.display = selectedItemIndex <= 0 ? 'none' : 'block';
		this.getElementByIdExpectNonNull('itemVoteRightButton').style.display =
			selectedItemIndex >= itemManagementData.items.length - 1 ? 'none' : 'block';

		// Set icon images for the previous, current and next items
		for (let i = 0; i < itemManagementData.items.length; i++) {
			let itemPreviewElement: HTMLElement;
			if (i === selectedItemIndex) {
				itemPreviewElement = this.getElementByIdExpectNonNull('itemPreviewMainImage');
			} else if (i === selectedItemIndex - 1) {
				itemPreviewElement = this.getElementByIdExpectNonNull('itemPreviewLeftImage');
			} else if (i === selectedItemIndex + 1) {
				itemPreviewElement = this.getElementByIdExpectNonNull('itemPreviewRightImage');
			} else {
				continue;
			}
			const cachedImgResult = imageUrlCache.tryGet(`Icon_LevelItem_${itemManagementData.items[i].itemName}`);
			itemPreviewElement.style.backgroundImage = cachedImgResult.success ? `url('${cachedImgResult.url}')` : '';
		}

		const itemPositionButtonsPanel = this.getElementByIdExpectNonNull('itemPositionButtonsPanel');

		// Remove all but the first child from the list of position buttons
		//	(the first child is itemPositionButton which we want to clone)
		for (let i = itemPositionButtonsPanel.childNodes.length - 1; i > 1; i--) {
			itemPositionButtonsPanel.childNodes[i].remove();
		}
		// We'll use itemPositionButton as a template to clone from
		const itemPositionButton = this.getElementByIdExpectNonNull('itemPositionButton');

		// Add 'Place nowhere' button
		this.addItemPositionButton(itemPositionButtonsPanel, itemPositionButton, -1, itemManagementData);
		// Add an itemPositionButton clone for each placement position
		for (let i = 0; i < itemManagementData.placementPositionCount; i++) {
			this.addItemPositionButton(itemPositionButtonsPanel, itemPositionButton, i, itemManagementData);
		}
	};

	public initialiseWriterTextInputVisuals = (characterWritingData: CharacterWritingData | undefined): void => {
		this.setupWriterCharacterScrollButtons(characterWritingData);
		this.updateWriterTextInputSelectionVisuals(characterWritingData, true, true, true);
	};

	public updateWriterTextInputSelectionVisuals = (
		characterWritingData: CharacterWritingData | undefined,
		selectionChanged: boolean = true,
		updateButtonStyling: boolean = true,
		instantScroll: boolean = false
	): void => {
		if (!characterWritingData) {
			// Nothing to display, disable the ready (send) button for now (by applying button pressed styling)
			this.setReadyButtonPressed(true, false);
			return;
		}

		// Enable the ready (send) button only if the min. allowed number of characters was entered
		this.setReadyButtonPressed(characterWritingData.selectedCharacterText.length < WriterInputMinLength, false);

		if (!selectionChanged) {
			return;
		}

		// Display name of selected character
		const characterNameTextElement = this.getElementByIdExpectNonNull('writerCharacterNameText');
		if (characterNameTextElement.innerText !== characterWritingData.selectedCharacterDisplayName) {
			characterNameTextElement.classList.remove('fadeInQuickAnimated');
			characterNameTextElement.style.visibility = 'hidden';
			characterNameTextElement.innerText = characterWritingData.selectedCharacterDisplayName;
			setTimeout(() => {
				characterNameTextElement.classList.add('fadeInQuickAnimated');
				characterNameTextElement.style.visibility = 'visible';
			}, 10);
		}

		// Apply styling to selected character button
		const selectedElement = this.getElementByIdExpectNonNull(
			`writerCharacterButton${characterWritingData.selectedCharacterIndex}`
		);
		this.scrollElementIntoViewCentered(selectedElement, instantScroll);
		if (updateButtonStyling) {
			this.updateWriterCharacterScrollButtonsStyling(selectedElement);
		}

		// Update input field (TextArea) with any text that was already written for the selected character
		const inputFieldElement = this.getElementByIdExpectNonNull('writerInputField') as HTMLTextAreaElement;
		inputFieldElement.value = characterWritingData.selectedCharacterText;

		// Hide left/right arrow button if first/last character is selected
		const leftArrowButtonElement = this.getElementByIdExpectNonNull('writerCharacterLeftButton');
		const rightArrowButtonElement = this.getElementByIdExpectNonNull('writerCharacterRightButton');
		leftArrowButtonElement.classList.remove('disabledFade');
		rightArrowButtonElement.classList.remove('disabledFade');
		if (characterWritingData.isFirstCharacterSelected) {
			leftArrowButtonElement.classList.add('disabledFade');
		} else if (characterWritingData.isLastCharacterSelected) {
			rightArrowButtonElement.classList.add('disabledFade');
		}
	};

	public updateWriterCharacterScrollButtonsStyling = (selectedElement: Element): void => {
		const scrollList = this.getElementByIdExpectNonNull('writerCharacterScrollList');

		for (let child of scrollList.children) {
			if (child.classList.contains('placeholderButton')) {
				continue;
			}
			var childHTML = child as HTMLElement;
			if (child === selectedElement) {
				childHTML.style.opacity = '1';
				childHTML.classList.add('actionButtonSelected');
			} else {
				childHTML.style.opacity = '0.5';
				childHTML.classList.remove('actionButtonSelected');
			}
		}
	};

	public getCentremostElementInScrollView = (scrollElement: Element): { element: Element; elementIndex: number } => {
		const scrollElementRect = scrollElement.getBoundingClientRect();
		const containerCentreX = scrollElementRect.left + scrollElementRect.width / 2;

		let centremostElement: Element | null = null;
		let centremostElementIndex: number = 0;
		let minDistance = Infinity;

		let scrollIndex = 0;
		for (let child of scrollElement.children) {
			const childRect = child.getBoundingClientRect();
			const childCentreX = childRect.left + childRect.width / 2;

			const distanceX = Math.abs(containerCentreX - childCentreX);

			if (!child.classList.contains('placeholderButton') && distanceX < minDistance) {
				centremostElement = child;
				centremostElementIndex = scrollIndex - ViewManager.ScrollElementPadding;
				minDistance = distanceX;
			}
			scrollIndex++;
		}

		return { element: centremostElement!, elementIndex: centremostElementIndex };
	};

	public scrollElementIntoViewCentered = (selectedElement: Element, instantScroll?: boolean): void => {
		selectedElement.scrollIntoView({
			behavior: instantScroll ? 'instant' : 'auto',
			block: 'center',
			inline: 'center',
		});
	};

	/**
	 * Returns the join code that has been entered into the HTML input field
	 * IF the field is being displayed (otherwise empty string),
	 * also returns the player name that has been entered.
	 */
	public tryGetJoinCodeAndPlayerNameFromHTML = (): JoinGameParams => {
		let joinCode: JoinCode = '';
		const joinCodeTxt = document.getElementById('joinCode') as HTMLInputElement;
		if (joinCodeTxt.style.display !== 'none') {
			joinCode = joinCodeTxt.value.trim();
		}

		const playerNameInput = document.getElementById('playerName') as HTMLInputElement;
		const playerNameText = playerNameInput.value.trim();

		return { joinCode: joinCode, playerName: playerNameText };
	};

	private addItemPositionButton = (
		parent: HTMLElement,
		cloneFrom: HTMLElement,
		index: number,
		itemManagementData: ItemManagementData
	): void => {
		const clone = cloneFrom.cloneNode(true) as HTMLElement;
		clone.id = `${clone.id}${index}`;
		clone.style.display = 'flex';
		parent.appendChild(clone);

		const posText1 = clone.querySelector<HTMLElement>('.itemPositionText1');
		if (!posText1)
			throw new Error('Failed to get element with class "itemPositionText1" from cloned itemPositionButton');
		posText1.innerText = index < 0 ? 'Nowhere' : 'Position';

		const alphabet = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
		const posText2 = clone.querySelector<HTMLElement>('.itemPositionText2');
		if (!posText2)
			throw new Error('Failed to get element with class "itemPositionText2" from cloned itemPositionButton');
		posText2.innerText = index >= 0 && index < alphabet.length ? alphabet[index] : '';

		if (itemManagementData.selectedItem.positionIndex == index) {
			// This position is selected for the current item, so button is selected
			clone.classList.add('actionButtonSelected');
		} else if (itemManagementData.positionIsSelectedForAnyItem(index)) {
			// This position is selected for a different item, so button is disabled
			clone.classList.add('disabledButton');
		}
	};

	private setupWriterCharacterScrollButtons = (characterWritingData: CharacterWritingData | undefined): void => {
		const scrollListParent = this.getElementByIdExpectNonNull('writerCharacterScrollList');

		// Clear existing child elements
		for (let i = scrollListParent.childNodes.length - 1; i >= 0; i--) {
			scrollListParent.childNodes[i].remove();
		}

		if (characterWritingData === undefined) {
			return;
		}

		// writerCharacterPlaceholder is hidden and used as a template to clone from
		const characterPlaceholderElement = this.getElementByIdExpectNonNull('writerCharacterPlaceholder');
		// writerCharacterButton is hidden and used as a template to clone from
		const characterButtonElement = this.getElementByIdExpectNonNull('writerCharacterButton');

		// Buffer/placeholder elements for left side of scroll
		for (let i = 0; i < ViewManager.ScrollElementPadding; i++) {
			this.cloneAndAddElement(characterPlaceholderElement, scrollListParent, i.toString(), 'grid');
		}
		// Button for each character
		characterWritingData.characterNamesAndText.forEach((nameAndText, index) => {
			const clone = this.cloneAndAddElement(characterButtonElement, scrollListParent, index.toString(), 'grid');
			const imageElement = clone.querySelector<HTMLElement>('.writerCharacterImage') as HTMLImageElement;
			if (!imageElement) {
				throw new Error(
					'Failed to get image element with class "writerCharacterImage" from cloned writerCharacterButton'
				);
			}
			Log.debug(`Setting image: ${imageElement.className}`);
			const internalNameNoWhitespace = nameAndText.internalName.replace(/\s/g, '');
			imageElement.className = `writerCharacterImage actionButtonImage characterAvatarImage_default characterAvatarImage_${internalNameNoWhitespace}`;
		});
		// Buffer/placeholder elements for right side of scroll
		for (let i = 0; i < ViewManager.ScrollElementPadding; i++) {
			this.cloneAndAddElement(characterPlaceholderElement, scrollListParent, i.toString(), 'grid');
		}
	};

	private setElementShowing = (elementId: string, showing: boolean, showDisplayType: string = 'block'): void => {
		const element = document.getElementById(elementId);
		if (element) {
			element.style.display = showing ? showDisplayType : 'none';
		} else {
			Log.error(`Calling setElementShowing with invalid element id: ${elementId}`);
		}
	};

	private getImageNameFromAction = (actionName: string): string => {
		switch (actionName) {
			case 'move':
			case 'act':
			case 'throw':
				return actionName;
			case '':
				return 'wait';
			default:
				Log.warn(`getImageNameFromAction: Unexpected action name '${actionName}'`);
				return 'unknown';
		}
	};

	private cloneAndAddElement = (
		elementToClone: HTMLElement,
		parentElement: HTMLElement,
		idAppend: string,
		displayType: string
	): HTMLElement => {
		const clone = elementToClone.cloneNode(true) as HTMLElement;
		clone.id = `${clone.id}${idAppend}`;
		clone.style.display = displayType;
		parentElement.appendChild(clone);
		return clone;
	};

	private getElementByIdExpectNonNull = (id: string): HTMLElement => {
		const element = document.getElementById(id);
		if (!element) {
			throw new Error(`Expected to find element with id ${id}, but was null`);
		}
		return element;
	};
}

export const enum View {
	Default = 'Default',
	Connecting = 'Connecting',
	Lobby = 'Lobby',
	WaitOrAddPlayers = 'WaitOrAddPlayers',
	CharacterSelect = 'CharacterSelect',
	LookAtScreen = 'LookAtScreen',
	GameInputPhase = 'GameInputPhase',
	ItemPlacementVote = 'ItemPlacementVote',
	Reconnecting = 'Reconnecting',
	WriterTextInput = 'WriterTextInput',
}

const Log = LogFactory.build(ViewManager.name);

import Log from '../../src-ts/common/Log';

export class ItemManagementData {
	public readonly items: ItemWithPositionIndex[];
	public readonly placementPositionCount: number;

	private _selectedItemVoteIndex: number;
	public get selectedItemVoteIndex(): number {
		return this._selectedItemVoteIndex;
	}
	public get selectedItem(): ItemWithPositionIndex {
		return this.items[this.selectedItemVoteIndex];
	}

	public constructor(placementPositionCount: number, itemNames: string[]) {
		this.placementPositionCount = placementPositionCount;
		this._selectedItemVoteIndex = 0;

		this.items = [];
		itemNames.forEach((itemName) => {
			this.items.push(new ItemWithPositionIndex(itemName));
		});
	}

	public incrementSelectedItemVoteIndex = (): void => {
		if (this.selectedItemVoteIndex >= this.items.length - 1) {
			return;
		}
		this._selectedItemVoteIndex++;
	};
	public decrementSelectedItemVoteIndex = (): void => {
		if (this.selectedItemVoteIndex <= 0) {
			return;
		}
		this._selectedItemVoteIndex--;
	};
	public resetSelectedItemVoteIndex = (): void => {
		this._selectedItemVoteIndex = 0;
	};

	public selectPositionForCurrentItem = (positionIndex: number): boolean => {
		Log.log(
			`Selecting position index ${positionIndex} for selected item ${this.selectedItemVoteIndex} (${this.selectedItem.itemName})`
		);
		if (positionIndex < -1 || positionIndex >= this.placementPositionCount) {
			return false;
		}
		if (this.positionIsSelectedForAnyItem(positionIndex)) {
			return false;
		}
		const item = this.items[this.selectedItemVoteIndex];
		item.positionIndex = positionIndex;
		return true;
	};

	public autoSelectNextItemWithoutPositionAfterDelay = async (): Promise<void> => {
		await new Promise((_) => setTimeout(_, 200));
		// Auto-select the next item that doesn't have a position chosen (if any)
		for (let i = this.selectedItemVoteIndex; i < this.items.length; i++) {
			if (i == this.selectedItemVoteIndex) {
				continue;
			}
			const item = this.items[i];
			if (item.positionIndex < 0) {
				this._selectedItemVoteIndex = i;
				break;
			}
		}
	};

	public positionIsSelectedForAnyItem = (positionIndex: number): boolean => {
		if (positionIndex == -1) {
			// -1 = Nowhere, any number of items can select this
			return false;
		}
		for (let item of this.items) {
			if (item.positionIndex == positionIndex) {
				return true;
			}
		}
		return false;
	};

	public itemsToJSON = (): string => {
		let data: string = '';
		for (let item of this.items) {
			if (item.positionIndex < 0) {
				continue;
			}
			data = data.concat(`"placementPos${item.positionIndex}": ${JSON.stringify(item.itemName)},`);
		}
		return data;
	};
}

export class ItemWithPositionIndex {
	public readonly itemName: string;
	public positionIndex: number;

	public constructor(itemName: string) {
		this.itemName = itemName;
		this.positionIndex = -1; // -1 = Nowhere
	}
}

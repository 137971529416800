import { CharacterButtonState, View, ViewManager } from './ViewManager';
import { GameProxy } from './GameProxy';
import { LogFactory, Logger } from '../../src-ts/common/Log';
import MsgMessage from '../../src-ts/common/messages/MsgMessage';
import MsgInfoFromServer from '../../src-ts/common/messages/MsgInfoFromServer';
import { ServerInfoCode } from '../../src-ts/common/messages/ServerInfoCode';
import { ControllerState } from './ControllerState';
import { Turn, TurnSet } from './TurnSet';
import { TurnSetWithSelections } from './TurnSetWithSelections';
import { App } from './App';
import { JoinCode } from '../../src-ts/common/messages/JoinCode';
// import { BadWordsFilter } from 'bad-words';
import { validateJoinCodeAndPlayerName, validateWriterInputText } from './ControllerValidationHelper';
import { toString } from '../../src-ts/server/utils/JsonUtils';
import { CookieHelper } from './CookieHelper';
import { DeviceId } from '../../src-ts/common/messages/DeviceId';
import { WebSocketCloseCode } from '../../src-ts/common/WebSocketCloseCode';
import { ResponseCode, ResponseCodeToName } from '../../src-ts/common/messages/ResponseCode';
import { ISimpleEvent, SimpleEventDispatcher } from 'strongly-typed-events';
import { ControllerActionButton } from './ControllerActionButton';
import { GameMessageData } from './GameMessageData';
import { ItemManagementData } from './ItemManagementData';
import { CharacterWritingData } from './CharacterWritingData';

/**
 * One per player joined from this browser.
 * Has a GameProxy to interact with the actual game (via whichever communication vector system we're using).
 */
export class GameSession {
	private readonly app: App;
	private readonly viewManager: ViewManager;
	private readonly logger: Logger;

	/**
	 *  Whether the player is marked as ready.
	 *  Also used to de-dup messaging The Game.
	 */
	private isReady: boolean = false;

	/** Which of the turns in `turnSet` we are affecting (or `undefined` when none). */
	private currentlySelectingTurnNumber: number | undefined;

	private selectedActionNumber: number | undefined;

	/** Turns the user has programmed (or undefined before we know this game's number of turns). */
	private turnSet: TurnSet | undefined;
	private selectionWasMadeForTurn: boolean[] = [];

	/** Communicates with The Game. */
	private readonly game: GameProxy;

	public get sessionIndex(): number {
		return this._sessionIndex;
	}
	private set sessionIndex(value: number) {
		this._sessionIndex = value;
	}
	private _sessionIndex: number;

	private _playerName: string | undefined;

	private connecting: boolean = false;
	private joinedGame: boolean = false;

	/** Index of the selected character, set during game setup. -1 === none selected */
	private characterIndex: number;
	private selectedCharacterIndexes: number[];
	private allNonCharactersSelected: boolean;

	private _itemManagementData?: ItemManagementData;
	public get itemManagementData(): ItemManagementData | undefined {
		return this._itemManagementData;
	}

	private _characterWritingData?: CharacterWritingData;
	public get characterWritingData(): CharacterWritingData | undefined {
		return this._characterWritingData;
	}

	private currentControllerState: ControllerState;

	private turnsPerRound: number = App.DefaultTurnsPerRound;

	/** Configure game input selection behaviour: */
	private static readonly DefaultSelectedActionNumber: number = 0;
	private static readonly AutoSelectionEnabled: boolean = true;
	private static readonly CanReadyWithEmptyTurns: boolean = true;

	public static readonly CharacterPlayerCount = 4;
	public static readonly NonCharacterPlayerCount = 4;

	private readonly _onGameSessionEnded = new SimpleEventDispatcher<number>();

	public get onGameSessionEnded(): ISimpleEvent<number> {
		return this._onGameSessionEnded.asEvent();
	}

	public get isNonCharacterPlayer(): boolean {
		return (
			this.characterIndex >= GameSession.CharacterPlayerCount &&
			this.characterIndex < GameSession.CharacterPlayerCount + GameSession.NonCharacterPlayerCount
		);
	}

	constructor(
		app: App,
		sessionIndex: number,
		viewManager: ViewManager,
		private readonly cookieHelper: CookieHelper,
		playerName?: string
	) {
		window.addEventListener('popstate', this.onWindowPopState);

		this.logger = LogFactory.build(`${GameSession.name}(s${sessionIndex})`);
		this.app = app;
		this._sessionIndex = sessionIndex;
		this.characterIndex = -1;
		this.selectedCharacterIndexes = [];
		this.allNonCharactersSelected = false;
		this.viewManager = viewManager;
		this.currentControllerState = ControllerState.JoinScreen;

		this._playerName = playerName;

		this.app.imageURLCache.onImageUrlCreated.subscribe(this.onImageUrlCreated);

		// Do we have a deviceId in a cookie?
		const previousDeviceId = cookieHelper.getDeviceIdForSessionIndex(sessionIndex);
		const previousJoinCode = cookieHelper.getJoinCode();
		this.logger.log(`Previous deviceId:${previousDeviceId}, previous joinCode:${previousJoinCode}`);
		this.game = new GameProxy(previousDeviceId, previousJoinCode, sessionIndex);

		// Subscribe for messages from game
		this.game.onMsgMessage.subscribe(this.onMsgMessage);
		this.game.onMsgServerInfo.subscribe(this.onMsgServerInfo);
		// Subscribe for connection established/close/error events from WebSocket
		this.game.onConnectionEvent.subscribe(this.onConnectionEvent);
		this.game.onDeviceIdAcquired.subscribe(this.onDeviceIdAcquired);

		this.game.onJoinCodeSet.subscribe(this.viewManager.setJoinCodeText);
		if (previousJoinCode) {
			this.viewManager.setJoinCodeText(previousJoinCode);
		}

		// ParcelJS HOt module reloading = only during development
		// See https://parceljs.org/features/development/
		if (module.hot) {
			this.logger.debug('Registering ParcelJS module reloading handlers');
			module.hot.dispose((data: any) => {
				this.logger.warn('ParcelJS Hot Module Reload = dispose()');
				if (!this.game) return;

				return this.game.closeConnection(
					false,
					WebSocketCloseCode.ClientConnectionTimeoutRetrying,
					'ParcelJS reload (GameSession)'
				);
			});
			module.hot.accept(async () => {
				this.logger.warn('ParcelJS Hot Module Reload = accept() (reload)');
				if (!this.game) return;

				await this.game.closeConnection(
					false,
					WebSocketCloseCode.ClientConnectionTimeoutRetrying,
					'ParcelJS reloaded (GameSession)'
				);

				this.logger.warn('ParcelJS Hot Module Reload = accept() = reconnecting');
				await this.tryConnect();
			});
		}
	}

	public connectToGame = async (): Promise<void> => {
		return this.tryConnect();
	};

	public get selectedCharacterIndex(): number {
		return this.characterIndex;
	}

	public get deviceId(): string {
		return this.game.deviceId ?? '';
	}

	public get playerName(): string | undefined {
		return this._playerName;
	}

	public get playerIsReady(): boolean {
		return this.isReady;
	}

	public get hasJoinedGame(): boolean {
		return this.joinedGame;
	}

	private get isActiveInView(): boolean {
		return this.app.isActiveGameSessionInView(this);
	}

	private get isInitialSession(): boolean {
		return 0 === this.sessionIndex;
	}

	private tryConnect = async (): Promise<void> => {
		this.logger.debug(`tryConnect() connecting:${this.connecting}`);
		if (this.connecting) {
			return;
		}

		try {
			this.viewManager.show(View.Connecting);

			// TODO-20230114: Show some "Connecting" message to user
			this.connecting = true;
			await this.game.connect();
			// No longer get deviceId here -- done automatically within connect() (and especially reconnect when it restores existing deviceId)
			this.connecting = false;

			await this.connected();
		} catch (e) {
			this.connecting = false;

			if (!e || !(e instanceof Error)) {
				if ('string' === typeof e) {
					e = new Error(e);
				} else {
					e = new Error('Unknown error.');
				}
			}
			this.logger.error('Failed to connect:', (<Error>e).message, ': ', e);

			// (onConnectionEvent handles showing error dialog to player)
		}
	};

	private onImageUrlCreated = (imageName: string) => {
		if (!this.isActiveInView) {
			return;
		}
		if (this.currentControllerState === ControllerState.ItemPlacementVote) {
			this.updateItemPlacementVoteVisuals();
		}
	};

	public endSession = async (closeConnection: boolean = true): Promise<void> => {
		this.logger.log(`Ending GameSession (${this.sessionIndex}) and closing connection`);

		// Hide tab that was used to switch to this session
		this.viewManager.setPlayerTabShowing(this.sessionIndex, false);

		// Unsub/remove listeners
		window.removeEventListener('popstate', this.onWindowPopState);
		this.game.onMsgMessage.unsubscribe(this.onMsgMessage);
		this.game.onMsgServerInfo.unsubscribe(this.onMsgServerInfo);
		this.game.onConnectionEvent.unsubscribe(this.onConnectionEvent);
		this.game.onJoinCodeSet.unsubscribe(this.viewManager.setJoinCodeText);

		this.cookieHelper.clearDeviceIdForSessionIndex(this.sessionIndex);
		// Join code cookie is cleared only when ALL game sessions were ended (see: App.onGameSessionEnded)

		if (closeConnection) {
			await this.game.closeConnection(true);
		}

		this._onGameSessionEnded.dispatch(this.sessionIndex);
	};

	/**
	 * Called when this GameSession becomes the activeGameSessionInView in App (e.g. when switching tabs)
	 * (Hence, we can assume isActiveInView === true here)
	 * @param alreadyActive Whether this session was switched to while already being the activeGameSessionInView.
	 */
	public becameActiveSession = (alreadyActive: boolean): void => {
		this.logger.debug(
			`GameSession ${this.sessionIndex} became active session (alreadyActive:${alreadyActive}), controllerState:${this.currentControllerState}`
		);

		switch (this.currentControllerState) {
			case ControllerState.JoinScreen:
			case ControllerState.WaitingForSetup:
			case ControllerState.WatchingActions:
			case ControllerState.ItemPlacementResults:
			case ControllerState.LevelFinished:
				// Does nothing
				break;

			case ControllerState.WaitingForInput:
				if (this.isNonCharacterPlayer) {
					this.viewManager.show(View.WriterTextInput, true);
					this.updateWriterTextInputVisuals();
				} else {
					this.viewManager.show(View.GameInputPhase, true);
					// Initialise button visuals from the active session's TurnSet and ensure GameInputPhase is showing
					this.initialiseInputPhaseVisuals();
				}
				break;

			case ControllerState.CharacterSelect:
				this.updateCharacterSelectVisuals();
				break;

			case ControllerState.ItemPlacementVote:
				this.updateItemPlacementVoteVisuals();
				break;

			default:
				throw new Error(`Unexpected ControllerState for becameActiveSession: ${this.currentControllerState}`);
		}

		// Update tab visuals to ensure correct session is visually selected
		this.updatePlayerTabVisuals();
	};

	public joinGame = async (joinGameParams: JoinGameParams): Promise<{ success: boolean; joinCode?: JoinCode }> => {
		this.logger.debug('joinGame(', joinGameParams, `) = GameSession ${this.sessionIndex}: Joining game`);
		if (this.currentControllerState !== ControllerState.JoinScreen) {
			this.logger.error(
				`joinGame should only be called when currentControllerState === JoinScreen, not ${this.currentControllerState}`
			);
			return { success: false };
		}

		const joinCode = joinGameParams.forceJoinCode ?? joinGameParams.joinCode;
		// Ensure the joinCode that will be validated is definitely the joinCode we are using
		//	(i.e. if we are using forceJoinCode, we want to make sure we're validating that one)
		joinGameParams.joinCode = joinCode;

		const validationResult = validateJoinCodeAndPlayerName(joinGameParams);
		if (!validationResult.isValid) {
			await this.viewManager.showInfoPopupDialog('Error', validationResult.errorText!, null);
			return { success: false };
		}

		// TODO-2022/08/15: Encode/strip player name so no other dodgy content! (HTML exploits?)
		this._playerName = joinGameParams.playerName;
		this.viewManager.setPlayerTabShowing(this.sessionIndex, true);
		this.game.setCurrentJoinCode(joinCode);

		if (this.isActiveInView) {
			this.viewManager.hideAll();
		}

		try {
			await this.game.joinGame(joinCode, joinGameParams.playerName);
			this.logger.debug('Joined.  Awaiting start.');

			if (this.isActiveInView) {
				this.viewManager.setupJoinedPlayersList(this.app.allJoinedPlayerNames, this.app._sessionCount);
				this.viewManager.show(View.WaitOrAddPlayers, true);
			}

			this.joinedGame = true;
			return { success: true, joinCode: joinCode };
		} catch (e) {
			if (!e || !(e instanceof Error)) {
				e = new Error('Error:' + e);
			}
			let error = <Error>e;
			// TODO-20230509: Better way to assert we know responseCode is in here
			const responseCode: ResponseCode =
				'responseCode' in error ? (<any>error).responseCode : ResponseCode.UnknownDoNotUse;
			const responseCodeName = ResponseCodeToName(responseCode);
			this.logger.error('Failed to join game, try again:', error.message, responseCodeName, e);

			if (this.isActiveInView) {
				// TODO-2022/08/15: Use ResponseCode + messages table for errors --> L10N!
				if (ResponseCode.JoinCodeNotAllowed === responseCode) {
					await this.viewManager.showInfoPopupDialog(
						'Error',
						"Cannot join that game, maybe it's full?",
						() => this.viewManager.show(View.Lobby, true),
						'Close'
					);
				} else if (
					ResponseCode.JoinCodeInvalid === responseCode ||
					error.message.replace(/\s/g, '').toLowerCase().includes('joincode')
				) {
					// Error message (removed spaces & lowercase) contains 'joincode', show specific join code error popup
					await this.viewManager.showInfoPopupDialog(
						'Error',
						"We don't know that join code, please check it's right?",
						() => this.viewManager.show(View.Lobby, true),
						'Close'
					);
				} else {
					await this.viewManager.showInfoPopupDialog(
						'Error',
						'Failed to join game.',
						() => this.viewManager.show(View.Lobby, true),
						'Close',
						true,
						error.message
					);
				}
			}

			return { success: false };
		}
	};

	public consumeEvent = (evt: Event) => {
		/*evt = evt || window.event; -- latter is deprecated! */
		if ('undefined' == typeof evt.stopPropagation) {
			evt.cancelBubble = true;
		} else {
			evt.stopPropagation();
		}
	};

	/**
	 * Called on window PopStateEvent (occurs on browser 'back' button press)
	 * or when clicking/tapping blank space in a sub-menu to go back
	 */
	public onCancelOrBack = (): void => {
		switch (this.viewManager.getCurrentViewId()) {
			case View.CharacterSelect:
			case View.GameInputPhase:
			case View.ItemPlacementVote:
			case View.WriterTextInput:
				if (this.isActiveInView) {
					this.viewManager.showGameMenu();
				}
				break;
			default:
				break;
		}
	};

	public requestCharacterSelection = async (newCharacterIndex: number): Promise<void> => {
		if (this.currentControllerState !== ControllerState.CharacterSelect) {
			this.logger.error('selectACharacter should only be called when currentControllerState === CharacterSelect');
			return;
		}

		if (this.characterIndex === newCharacterIndex) {
			// The requested character was already selected by this device, instead
			//	set the character index to -1, which will deselect the character
			newCharacterIndex = -1;
		}

		try {
			await this.game.sendCharacterSelectRequest(newCharacterIndex);
		} catch (e: unknown) {
			this.logger.error('Failed to send character selection to The Game due to:', e);
		}
	};

	public requestNonCharacterSelection = async (): Promise<void> => {
		if (this.isNonCharacterPlayer) {
			// Already selected a non-character, deselect
			await this.requestCharacterSelection(-1);
			return;
		}
		// Try selecting any available non-character index
		try {
			await this.game.sendNonCharacterSelectRequest();
		} catch (e: unknown) {
			this.logger.error('Failed to send non-character selection to The Game due to:', e);
		}
	};

	public selectATurnNumber = (turnNumber: number): void => {
		if (undefined === this.turnSet) {
			throw new Error('Trying to select a turn with undefined turnSet');
		}
		if (this.currentControllerState !== ControllerState.WaitingForInput) {
			this.logger.error('selectATurnNumber should only be called when currentControllerState === WaitingForInput');
			return;
		}

		this.logger.log(`Selecting turn: ${turnNumber}`);

		this.currentlySelectingTurnNumber = turnNumber;
		this.cookieHelper.setSelectedTurnForSessionIndex(this.sessionIndex, turnNumber);

		if (this.isActiveInView) {
			// Update action previews with current actions for each turn
			for (let i = 0; i < this.turnSet!.numTurns; i++) {
				// this.viewManager.setActionPreviewVisuals(i, this.turnSet?.get(i).action!, this.turnSet?.get(i).data!);
			}

			this.viewManager.updateTurnSelectedVisuals(turnNumber, this.turnsPerRound);
		}

		if (this.selectionWasMadeForTurn[turnNumber]) {
			// Selection has already been made for this turn
			const actionNum = this.app.getActionNumberByName(this.turnSet.get(turnNumber).action);
			const selectedActionForThisTurn: number = actionNum;
			this.selectActionNumber(selectedActionForThisTurn, false);
		} else {
			// No selection has been made for this turn, select the default action
			this.selectActionNumber(GameSession.DefaultSelectedActionNumber, false);
		}
	};

	public selectActionNumber = async (actionNumber: number, playerPressed: boolean): Promise<void> => {
		if (undefined === this.turnSet) {
			throw new Error('Trying to select an action, but turnSet is undefined');
		}
		if (this.currentControllerState !== ControllerState.WaitingForInput) {
			throw new Error('selectActionWithName should only be called when currentControllerState === WaitingForInput');
		}
		if (undefined === this.currentlySelectingTurnNumber) {
			throw new Error('Trying to select an action, but no turn number was selected yet');
		}
		if (actionNumber < 0 || actionNumber >= App.ActionButtonCount) {
			throw new Error(`actionNumber (${actionNumber}) is out of range of the available action types`);
		}
		const selectedTurn = this.currentlySelectingTurnNumber;

		// When the player presses a new action button, reset any existing action/data for this turn
		if (playerPressed) {
			this.turnSet.set(selectedTurn, new Turn('', ''));
			this.selectionWasMadeForTurn[selectedTurn] = false;
			this.viewManager.setTurnButtonVisual(selectedTurn, true, undefined, '', this.app.imageURLCache);
		}

		// Then select the new action (this does not set the turn, that only happens when selection is complete, which may require extra data, e.g. direction)
		this.selectedActionNumber = actionNumber;
		const buttonData = this.app.getActionButtonData(actionNumber);
		const actionRequiresData: boolean = buttonData.hasDirectionDataOrDefault;

		if (this.isActiveInView) {
			this.viewManager.updateActionSelectedVisuals(actionNumber);
			this.viewManager.setDirectionalButtonsForAction(buttonData);
			this.viewManager.setDirectionButtonsEnabled(actionRequiresData && buttonData.enabledOrDefault);
			this.viewManager.updateDirectionSelectedVisuals('');
		}

		const dataForCurrentTurn = this.turnSet.get(selectedTurn).data;
		if (actionRequiresData && dataForCurrentTurn !== '') {
			await this.selectDataForCurrentTurn(dataForCurrentTurn, false);
		} else if (!actionRequiresData) {
			// Action does not require data - selection is complete and the turn can be set
			await this.completeTurnSelection(actionNumber, buttonData.action, '', playerPressed);
		}
	};

	public selectDataForCurrentTurn = async (data: string, playerPressed: boolean): Promise<void> => {
		if (undefined === this.selectedActionNumber) {
			throw new Error('Trying to set data with no selected action number');
		}

		const buttonData = this.app.getActionButtonData(this.selectedActionNumber);
		if (!buttonData.hasDirectionDataOrDefault) {
			this.logger.warn(`Selecting data for an action that does not require any (action ${this.selectedActionNumber})`);
			return;
		}

		if (this.isActiveInView) {
			this.viewManager.updateDirectionSelectedVisuals(data);
		}

		// Action does not require data - we're done selecting for this turn
		await this.completeTurnSelection(this.selectedActionNumber, buttonData.action, data, playerPressed);
	};

	private completeTurnSelection = async (
		actionIndex: number,
		actionName: string,
		data: string,
		playerPressed: boolean
	): Promise<void> => {
		if (undefined === this.turnSet) {
			throw new Error('Trying to set action and data for selected turn, but turnSet is undefined');
		}
		if (undefined === this.currentlySelectingTurnNumber) {
			throw new Error('Trying to set action and data for selected turn, but currentlySelectingTurnNumber is undefined');
		}
		const selectedTurn = this.currentlySelectingTurnNumber;

		this.logger.log(
			`Completing selection for turn ${selectedTurn} - ${actionName} ${data} (Player pressed: ${playerPressed})`
		);

		this.turnSet.set(selectedTurn, new Turn(actionName, data));
		this.selectionWasMadeForTurn[selectedTurn] = true;
		this.cookieHelper.setTurnSetForSessionIndex(
			this.sessionIndex,
			new TurnSetWithSelections(this.turnSet, this.selectionWasMadeForTurn)
		);

		if (playerPressed) {
			// Un-ready if the player made a new selection
			try {
				await this.setReady(false);
			} catch (e: unknown) {
				this.logger.error(`ERROR: Failed to tell The Game we are not ready:`, e);
			}
		}

		if (this.isActiveInView) {
			this.viewManager.setTurnButtonVisual(
				selectedTurn,
				true,
				this.app.getActionButtonData(actionIndex),
				data,
				this.app.imageURLCache
			);
			// Allow the player to press 'ready' when they have selected an action for every turn
			if (GameSession.CanReadyWithEmptyTurns) {
				this.viewManager.setReadyButtonShowing(true);
			} else {
				this.viewManager.setReadyButtonShowing(this.allTurnsSelected());
			}
		}

		// If this selection was triggered by the player pressing a button,
		//	automatically select the next empty turn (unless AutoSelectionEnabled is false)
		//	If there are no empty turns, just select the next turn
		if (!playerPressed || !GameSession.AutoSelectionEnabled) {
			return;
		}
		let nextTurn = selectedTurn < this.turnsPerRound - 1 ? selectedTurn + 1 : 0;
		let nextEmptyTurn: number | undefined;
		for (let i = 0; i < this.turnsPerRound; i++) {
			if (!this.selectionWasMadeForTurn[i]) {
				// Found an empty turn
				if (i > selectedTurn) {
					// If an empty turn exists after the current one, select it
					nextEmptyTurn = i;
					break;
				} else if (nextEmptyTurn === undefined) {
					// The first empty turn will be selected if there are no empty turns after the current one
					nextEmptyTurn = i;
				}
			}
		}
		this.selectATurnNumber(nextEmptyTurn ?? nextTurn);
	};

	private allTurnsSelected = (): boolean => {
		for (let i = 0; i < this.turnsPerRound; i++) {
			if (!this.selectionWasMadeForTurn[i]) {
				return false;
			}
		}
		return true;
	};

	public changeSessionIndex = (newIndex: number) => {
		this.viewManager.setPlayerTabShowing(this.sessionIndex, false);

		// Update cookies so can resume if reloaded
		this.cookieHelper.clearDeviceIdForSessionIndex(this.sessionIndex);
		this.cookieHelper.setDeviceIdForSessionIndex(newIndex, this.deviceId);

		this.sessionIndex = newIndex;
		this.viewManager.setPlayerTabShowing(this.sessionIndex, true);
	};

	public onReadyButtonPressed = async (ready: boolean): Promise<void> => {
		if (this.isNonCharacterPlayer && this.currentControllerState === ControllerState.WaitingForInput) {
			// For non-character players in WaitingForInput state, the 'Ready' button instead becomes a 'send narrative text' button
			await this.trySendNarrativeCharacterText();
			return;
		}
		await this.setReady(ready, true);
	};

	private setReady = async (ready: boolean, sendState = true): Promise<void> => {
		this.logger.debug('setReady(', ready, ', sendState:', sendState, ')');
		if (
			this.currentControllerState !== ControllerState.WaitingForInput &&
			this.currentControllerState !== ControllerState.CharacterSelect &&
			this.currentControllerState !== ControllerState.ItemPlacementVote
		) {
			this.logger.error(
				'setReady should only be called when currentControllerState is WaitingForInput, CharacterSelect or ItemPlacementVote'
			);
			return;
		}

		if (this.isReady === ready) {
			this.logger.debug('setReady: Already marked as ready:', ready);
			return;
		}
		try {
			if (sendState) {
				const sendSuccess = await this.trySendReadyState(ready);
				if (!sendSuccess) {
					// Logging handled in trySendReadyState
					return;
				}
			}
			this.isReady = ready;
			this.logger.log('setReady: Ready status successfully set to:', ready);
		} catch (e: unknown) {
			this.logger.error('Failed to send ready status to The Game due to:', e);
			return;
		}

		this.viewManager.updateSessionReadyStateVisuals(this.sessionIndex, ready);
		if (this.isActiveInView) {
			this.viewManager.setReadyButtonPressed(ready);
		}

		if (ready) {
			// Switch to the next GameSession where the player is not 'ready' so they can make their selections
			this.app.switchToNextNonReadyGameSession(this.sessionIndex);
			return;
		}
	};

	private trySendReadyState = async (ready: boolean): Promise<boolean> => {
		if (!ready) {
			// Send to game: Not ready!
			await this.game.sendReadyState(false);
			return true;
		}

		// Send to game: Ready! (And other things depending on state)
		if (this.currentControllerState === ControllerState.WaitingForInput) {
			if (this.isNonCharacterPlayer) {
				this.logger.error(
					`trySendReadyState: No need to send ready state for non-character player in WaitingForInput state`
				);
				return false;
			}
			// Send actions *and* set ready true
			await this.game.sendTurnSetAndReady(this.turnSet!);
		} else if (this.currentControllerState === ControllerState.ItemPlacementVote) {
			// Sent item placement data *and* set ready to true
			if (!this.itemManagementData) {
				this.logger.error(`Trying to send item management results to game with null ItemManagementData`);
				return false;
			}
			await this.game.sendItemManagementResultsAndReady(this.itemManagementData, this.characterIndex);
		} else {
			this.logger.error(
				`Trying to send ready state to game in unexpected controller state: ${this.currentControllerState}`
			);
		}
		return true;
	};

	private trySendNarrativeCharacterText = async (): Promise<boolean> => {
		// Non-character player: Send narrative text *and* set ready true
		if (this.characterWritingData === undefined) {
			this.logger.error(
				`No characterWritingData in trySendNarrativeCharacterText for non-player character (GameSession ${this.sessionIndex})`
			);
			return false;
		}
		// Validate writer text input before sending
		const validationResult = validateWriterInputText(this.characterWritingData.selectedCharacterText);
		if (!validationResult.isValid) {
			await this.viewManager.showInfoPopupDialog('Error', validationResult.errorText!, null);
			return false;
		}
		await this.game.sendNarrativeCharacterText(this.characterWritingData);

		// Message was sent, reset/clear text
		this.characterWritingData.setTextInputForSelectedCharacter('');

		// Update visuals for writer input view (including disabling the 'ready'/'send' button),
		//	only if this is still the active game session after text was sent
		if (this.isActiveInView) {
			this.viewManager.updateWriterTextInputSelectionVisuals(this.characterWritingData);
		}

		// Update character text cookie so cleared text is not saved & restored
		const textForAllCharacters = this.characterWritingData.getCharacterNameToTextMap();
		this.cookieHelper.setCharacterTextForSessionIndex(this.sessionIndex, textForAllCharacters);

		return true;
	};

	// Called on successful connection complete
	private connected = async (): Promise<void> => {
		// Show another tab for switching to this session
		this.viewManager.setPlayerTabShowing(this.sessionIndex, true);

		const isRejoining: boolean = this.game.didRejoinPreviousSession;
		// If we rejoined an 'in-progress' game (possibly still being set-up!), the Game sends a message to tell us the state to go to.
		this.logger.log(`Connected: isRejoining:${isRejoining}`);

		// Initialise controller state to JoinScreen when we connect
		//	If reconnecting, the game will send a msg with whatever state we need to return to
		this.setControllerState(ControllerState.JoinScreen);

		if (isRejoining) {
			this.joinedGame = true;
			this.viewManager.show(View.WaitOrAddPlayers, true);
			this.logger.debug(
				`showing WaitOrAddPlayers for ${this.sessionIndex} which ${
					this.isInitialSession ? 'is' : 'is not'
				} the initial session`
			);
		} else {
			this.viewManager.setupLobbyUI(this.isInitialSession);
			this.viewManager.show(View.Lobby, true);
			this.logger.debug(
				`showing lobby for ${this.sessionIndex} which ${this.isInitialSession ? 'is' : 'is not'} the initial session`
			);
		}
	};

	// receives message from The Game!
	private onMsgMessage = async (msgMessage: MsgMessage): Promise<void> => {
		this.logger.silly('onMsgMessage:', msgMessage); // TODO-20230309: TEMP debug

		let data: any; // TODO-2021/09/26: Better type?
		try {
			data = msgMessage.getDataDecoded();
		} catch (e: unknown) {
			this.logger.error('Failed to parse message from Game due to:', e, 'message was:', msgMessage);
			return;
		}

		if (data.hasOwnProperty(GameMessageData.BootController)) {
			this.onBootControllerMsgMessage();
			// We've been booted and the connection was closed, nothing more to do here
			return;
		}
		if (data.hasOwnProperty(GameMessageData.CustomController.ActionButton)) {
			this.onCustomControllerActionButtonMsgMessage(data);
			// Action button data is not sent as part of a combined message, so no need to check others
			return;
		}
		if (data.hasOwnProperty(GameMessageData.ImageBase64.ImageName)) {
			this.onImageBase64MsgMessage(data);
			// Base64 image data is not sent as part of a combined message, so no need to check others
			return;
		}

		// Not else-if chain to allow combined reconnect messages
		// (have SetState, ConfirmCharacters, and (sometimes) ItemManagement in one)

		if (data.hasOwnProperty(GameMessageData.ItemManagement.Name)) {
			this.onItemManagementMsgMessage(data);
		}

		if (data.hasOwnProperty(GameMessageData.NarrativeCharacters.Name)) {
			this.onNarrativeCharactersMsgMessage(data);
		}

		if (data.hasOwnProperty(GameMessageData.ConfirmCharacters.Name)) {
			this.onConfirmCharactersMsgMessage(data);
		}

		if (data.hasOwnProperty(GameMessageData.NumTurns)) {
			this.onNumTurnsMsgMessage(data);
		}

		if (data.hasOwnProperty(GameMessageData.SetState)) {
			const newStateTxt = data[GameMessageData.SetState];
			const newState: ControllerState = ControllerState[newStateTxt as keyof typeof ControllerState];
			// TODO: Validate it is a `ControllerState`
			this.setControllerState(newState);
		}
	};

	private onBootControllerMsgMessage = async (): Promise<void> => {
		this.logger.log(`deviceId ${this.deviceId} was booted from the game, ending GameSession`);
		// End this session without closing connection - the actual device disconnect/removal is handled in MsgBootDevice
		await this.endSession(false);
		// Display dialog to notify the player(s) that someone on this device was booted
		this.viewManager.showInfoPopupDialog('', `${this.playerName} was removed from the game by the host.`, null);
	};

	private onCustomControllerActionButtonMsgMessage = (data: any): void => {
		const actionButtonData = data[GameMessageData.CustomController.ActionButton];
		if (!actionButtonData.hasOwnProperty('buttonIndex')) {
			throw new Error(`No buttonIndex property in received controller action button data`);
		}
		let buttonIndex: number = actionButtonData['buttonIndex'];
		this.setActionButtonFromControllerData(actionButtonData as ControllerActionButton, buttonIndex);
	};

	private onImageBase64MsgMessage = (data: any): void => {
		if (!data.hasOwnProperty(GameMessageData.ImageBase64.Data)) {
			throw new Error(
				`No ${GameMessageData.ImageBase64.Data} property in data for msg with ${GameMessageData.ImageBase64.ImageName}: ${data}`
			);
		}
		const imageName = data[GameMessageData.ImageBase64.ImageName];
		const imageBase64Data = data[GameMessageData.ImageBase64.Data];
		// Create image URL from base 64 data and add it to the image URL cache
		this.app.imageURLCache.createImageObjectURL(imageName, imageBase64Data);
	};

	private onItemManagementMsgMessage = async (data: any): Promise<void> => {
		if (!data.hasOwnProperty(GameMessageData.ItemManagement.Positions)) {
			throw new Error(
				`No ${GameMessageData.ItemManagement.Positions} property in ${GameMessageData.ItemManagement.Name}: ${data}`
			);
		}
		if (!data.hasOwnProperty(GameMessageData.ItemManagement.ItemNames)) {
			throw new Error(
				`No ${GameMessageData.ItemManagement.ItemNames} property in ${GameMessageData.ItemManagement.Name}: ${data}`
			);
		}
		const placementPositions: number[][] = data[GameMessageData.ItemManagement.Positions];
		const itemNames: string[] = data[GameMessageData.ItemManagement.ItemNames];
		this._itemManagementData = new ItemManagementData(placementPositions.length, itemNames);

		if (this.isActiveInView && this.currentControllerState === ControllerState.ItemPlacementVote) {
			this.updateItemPlacementVoteVisuals();
		}
	};

	private onNarrativeCharactersMsgMessage = async (data: any): Promise<void> => {
		if (!data.hasOwnProperty(GameMessageData.NarrativeCharacters.CharacterInternalNames)) {
			throw new Error(
				`No ${GameMessageData.NarrativeCharacters.CharacterInternalNames} property in ${GameMessageData.NarrativeCharacters.Name}: ${data}`
			);
		}
		if (!data.hasOwnProperty(GameMessageData.NarrativeCharacters.CharacterDisplayNames)) {
			throw new Error(
				`No ${GameMessageData.NarrativeCharacters.CharacterDisplayNames} property in ${GameMessageData.NarrativeCharacters.Name}: ${data}`
			);
		}
		const characterInternalNames: string[] = data[GameMessageData.NarrativeCharacters.CharacterInternalNames];
		const characterDisplayNames: string[] = data[GameMessageData.NarrativeCharacters.CharacterDisplayNames];
		this._characterWritingData = new CharacterWritingData(
			characterInternalNames,
			characterDisplayNames,
			this.cookieHelper.getCharacterTextForSessionIndex(this.sessionIndex)
		);

		if (
			this.isActiveInView &&
			this.currentControllerState === ControllerState.WaitingForInput &&
			this.isNonCharacterPlayer
		) {
			this.updateWriterTextInputVisuals();
		}
	};

	private onConfirmCharactersMsgMessage = async (data: any): Promise<void> => {
		const allSelected: boolean = data[GameMessageData.ConfirmCharacters.Name];
		if (!data.hasOwnProperty(GameMessageData.ConfirmCharacters.AllNonCharactersSelected)) {
			throw new Error(
				`No ${GameMessageData.ConfirmCharacters.AllNonCharactersSelected} property in ${GameMessageData.ConfirmCharacters.Name}: ${data}`
			);
		}
		this.allNonCharactersSelected = data[GameMessageData.ConfirmCharacters.AllNonCharactersSelected];
		const newCharacterIndex: number | undefined = data[this.deviceId];

		this.selectedCharacterIndexes = [];
		for (const property in data) {
			this.logger.debug(`Checking data: ${property}`);
			// Skip known commands (supplied with the reconnect message)
			switch (property) {
				case GameMessageData.ConfirmCharacters.Name:
				case GameMessageData.ConfirmCharacters.AllNonCharactersSelected:
				case GameMessageData.SetState:
				case GameMessageData.ThrowEnabled:
				case GameMessageData.NumTurns:
				case GameMessageData.ItemManagement.Name:
				case GameMessageData.ItemManagement.ItemNames:
				case GameMessageData.ItemManagement.Positions:
				case GameMessageData.NarrativeCharacters.Name:
				case GameMessageData.NarrativeCharacters.CharacterInternalNames:
				case GameMessageData.NarrativeCharacters.CharacterDisplayNames:
				case MsgMessage.MsgTopicName:
					continue;
			}

			// TODO-2023/03/19 This assumes a 'ConfirmCharacters' message only has an array mapping deviceId to character number.
			//   This precludes/limits using a combined message (which we're now doing).  The message sent needs restructuring.
			//   For now, skipping anything not a number but any combined message that uses numeric values will cause problems here!
			if (!('number' === typeof data[property])) {
				this.logger.warn(`Skipping data:${property} which is not a number but got: ${data[property]}`);
				continue;
			}
			this.selectedCharacterIndexes.push(data[property]);
		}

		if (
			newCharacterIndex !== undefined &&
			newCharacterIndex >= -1 &&
			newCharacterIndex < GameSession.CharacterPlayerCount + GameSession.NonCharacterPlayerCount
		) {
			this.logger.log(
				`Confirming character selection index for ${this.deviceId}: ${newCharacterIndex} (All selected: ${allSelected})`
			);
			this.characterIndex = newCharacterIndex;
		} else {
			this.logger.error(
				`Invalid character index when receiving ${GameMessageData.ConfirmCharacters.Name}: ${newCharacterIndex}`
			);
		}

		if (this.isActiveInView && this.currentControllerState === ControllerState.CharacterSelect) {
			this.updateCharacterSelectVisuals();
		}
		this.updatePlayerTabVisuals();
	};

	private onNumTurnsMsgMessage = (data: any): void => {
		let newTurnsPerRound: number = data[GameMessageData.NumTurns];
		if (newTurnsPerRound < App.MinValidTurnsPerRound) {
			newTurnsPerRound = App.MinValidTurnsPerRound;
		} else if (newTurnsPerRound > App.MaxValidTurnsPerRound) {
			newTurnsPerRound = App.MaxValidTurnsPerRound;
		}
		if (newTurnsPerRound !== this.turnsPerRound) {
			this.turnsPerRound = newTurnsPerRound;
			if (this.currentControllerState == ControllerState.WaitingForInput) {
				this.resetForNewRound();
			}
		}
	};

	private setActionButtonFromControllerData = async (
		actionButtonData: ControllerActionButton,
		buttonIndex: number
	): Promise<void> => {
		if (buttonIndex < 0) {
			throw new Error(
				`Invalid buttonIndex (${buttonIndex}) when trying to add action button using data received from game`
			);
		}
		if (buttonIndex >= App.ActionButtonCount) {
			this.logger.warn(
				`Trying to add action button with button index: ${buttonIndex}. Skipping since we currently only allow a maximum of ${App.ActionButtonCount} buttons to be defined.`
			);
			return;
		}

		// Create new ControllerActionButton from received data
		const actionButtonInstance = new ControllerActionButton(
			false,
			actionButtonData.action,
			actionButtonData.enabled,
			actionButtonData.hasDirectionData,
			actionButtonData.label,
			actionButtonData.imageName,
			actionButtonData.imageBase64,
			actionButtonData.mainColour,
			actionButtonData.secondaryColour
		);

		// Create image URL from base 64 data and add it to the image URL cache
		await actionButtonInstance.createImageObjectURL(this.app.imageURLCache);

		// Assign the new button to the CustomControllerData that's used to determine how buttons will look & their associated actions
		this.app.setCustomControllerActionButton(buttonIndex, actionButtonInstance);
	};

	// Receives messages with ServerInfo command from the game
	private onMsgServerInfo = async (msgServerInfo: MsgInfoFromServer) => {
		let infoCode: ServerInfoCode = msgServerInfo.infoCode;
		switch (infoCode) {
			case ServerInfoCode.Connected:
			case ServerInfoCode.ControllerConnected:
			case ServerInfoCode.ControllerDisconnectedTemporarily:
			case ServerInfoCode.ControllerReconnected:
			case ServerInfoCode.ControllerDisconnectedPermanently:
				//nop
				// These aren't sent to the 'peer controllers' so never really seen
				this.logger.debug(`Info from server: peer (not us) controller:`, msgServerInfo);
				break;

			case ServerInfoCode.GameDisconnectedTemporarily:
			case ServerInfoCode.GameReconnected:
				this.logger.debug(`Info from server: Game:`, msgServerInfo);
				// TODO-20230220: Visualise?
				break;

			case ServerInfoCode.GameDisconnectedPermanently:
				if (this.isActiveInView) {
					// Game disconnected - show error then reset the app to start afresh
					await this.viewManager.showInfoPopupDialog(
						'Error',
						'Connection to the game was lost.',
						this.app.resetApp,
						'Close'
					);
				}
				break;

			case ServerInfoCode.UnknownDoNotUse:
				throw new Error(
					`onMsgServerInfo() received msg with bad ${ServerInfoCode}: ${infoCode}: ${toString(msgServerInfo)}`
				);

			default:
				throw new Error(`Unhandled ${ServerInfoCode} for onMsgServerInfo(): ${infoCode}: ${toString(msgServerInfo)}`);
		}
	};

	/**
	 * Receives connection established/closed/errored events from the server.
	 * Reconnection is automatically handled by the sender so this is mostly for UI updates.
	 */
	private onConnectionEvent = async (evt: Event | CloseEvent) => {
		// 2023/02/06: This is dispatched right before we attempt to reconnect (automatically)
		//   As such, perhaps show an info screen or perhaps start a timer before showing (and cancel if reconnected).
		if (!this.isActiveInView) {
			return;
		}

		if (evt instanceof CloseEvent) {
			// Connection closed
			this.logger.warn('Connection closed  = reconnecting.');
			this.viewManager.show(View.Reconnecting);
		} else {
			switch (evt.type) {
				case 'error':
					// Connection error
					this.logger.warn('Connection error = reconnecting');
					this.viewManager.show(View.Reconnecting);
					break;

				case 'open':
					// connected
					if (this.connecting) {
						this.logger.log(
							`First time connecting so stashing deviceId:${this.game.deviceId} in cookie to allow reconnecting in future`
						);
						if (undefined !== this.game.deviceId) {
							this.cookieHelper.setDeviceIdForSessionIndex(this.sessionIndex, this.game.deviceId!);
						}
						return;
					}
					// Hide reconnecting dialog
					this.viewManager.restorePreviousView();
					break;

				default:
					this.logger.warn(`UNHANDLED connection event type:"${evt.type}": ${toString(evt)}`);
					break;
			}
		}
	};

	private onDeviceIdAcquired = async (newDeviceId: DeviceId) => {
		if (undefined === newDeviceId) {
			const errMsg = 'Invalid deviceId supplied = ignoring';
			this.logger.error(errMsg);
			throw new Error(errMsg);
		}

		this.logger.debug(`Acquired DeviceId:${newDeviceId} - saving to cookie`);
		this.cookieHelper.setDeviceIdForSessionIndex(this.sessionIndex, newDeviceId);
	};

	private setControllerState(state: ControllerState): void {
		const prevState = this.currentControllerState;
		this.currentControllerState = state;
		this.actUponControllerState(prevState, state);
	}

	private async actUponControllerState(previousState: ControllerState, state: ControllerState): Promise<void> {
		switch (state) {
			case ControllerState.JoinScreen:
				break;

			case ControllerState.WaitingForSetup:
				// Set default controller data while setting up to be used if no custom data is received from the game
				this.app.resetControllerDataToDefault();
				this.cookieHelper.clearTurnSetForSessionIndex(this.sessionIndex);
				this.cookieHelper.clearSelectedTurnForSessionIndex(this.sessionIndex);
				break;

			case ControllerState.CharacterSelect:
				if (this.isActiveInView) {
					this.updateCharacterSelectVisuals();
				}
				// Ensure all tabs are not highlighted when entering char select
				//	(some may be left highlighted from the previous state)
				this.viewManager.updateSessionReadyStateVisuals(this.sessionIndex, this.isReady);
				this.updatePlayerTabVisuals();
				// Clear any stored player-written character text from a previous level/session
				this.cookieHelper.clearCharacterTextForSessionIndex(this.sessionIndex);
				break;

			case ControllerState.WaitingForInput:
				if (previousState !== state) {
					this.resetForNewRound();
				}
				if (this.isActiveInView) {
					if (this.isNonCharacterPlayer) {
						this.updateWriterTextInputVisuals();
					} else {
						this.initialiseInputPhaseVisuals();
					}
				}
				this.updatePlayerTabVisuals();
				break;

			case ControllerState.ItemPlacementVote:
				this.setReady(false, false);
				this.itemManagementData?.resetSelectedItemVoteIndex();
				this.updateItemPlacementVoteVisuals();
				break;

			case ControllerState.WatchingActions:
				this.cookieHelper.clearTurnSetForSessionIndex(this.sessionIndex);
				break;

			case ControllerState.ItemPlacementResults:
			case ControllerState.LevelFinished:
				break;

			default:
				throw new Error(`Unhandled controller state:${state}`);
		}

		if (state !== ControllerState.WaitingForInput) {
			this._characterWritingData = undefined;
		}

		await this.app.actUponControllerStateChange(state);
	}

	private updatePlayerTabVisuals = (): void => {
		if (this.app.activeGameSessionInView === undefined) {
			return;
		}
		this.viewManager.updatePlayerTabVisuals(
			this.app.activeGameSessionInView.sessionIndex,
			this.app.activeGameSessionInView.playerName ?? '',
			this.app.allSelectedCharacterIndexes
		);
	};

	/**
	 * Initialise button visuals from the active session's TurnSet and ensure GameInputPhase is showing.
	 * Called when switching to this GameSession during the input phase, ensures that
	 * visuals represent the data for the current session's player/turnset/ready state.
	 */
	private initialiseInputPhaseVisuals = (): void => {
		this.viewManager.updateSessionReadyStateVisuals(this.sessionIndex, this.isReady);

		if (!this.isActiveInView) {
			this.logger.debug('initialiseInputPhaseVisuals() but not the active view');
			return;
		}

		this.viewManager.updateCharacterPreviewImage(this.characterIndex);

		this.viewManager.positionReadyUnreadyButtonsForGameInput();
		this.viewManager.setReadyButtonPressed(this.isReady);
		if (GameSession.CanReadyWithEmptyTurns) {
			this.viewManager.setReadyButtonShowing(true);
		} else {
			this.viewManager.setReadyButtonShowing(this.allTurnsSelected());
		}

		// Setup turn selection buttons for num turns
		this.viewManager.setupTurnButtons(this.turnsPerRound);

		// Initialise turn button visuals from the chosen actions in this.turnSet
		for (let i = 0; i < this.turnsPerRound; i++) {
			const turn = this.turnSet!.get(i);
			this.viewManager.setTurnButtonVisual(
				i,
				this.selectionWasMadeForTurn[i],
				this.selectionWasMadeForTurn[i]
					? this.app.getActionButtonData(this.app.getActionNumberByName(turn.action))
					: undefined,
				turn.data,
				this.app.imageURLCache
			);
		}

		// Initialise all action buttons from CustomControllerData
		for (let i = 0; i < App.ActionButtonCount; i++) {
			const actionButtonData = this.app.getActionButtonData(i);
			this.viewManager.setActionButtonVisual(i, actionButtonData, this.app.imageURLCache);
			this.viewManager.setActionButtonEnabled(i, actionButtonData);
		}

		if (this.currentlySelectingTurnNumber !== undefined) {
			this.selectATurnNumber(this.currentlySelectingTurnNumber);
		}
	};

	public updateItemPlacementVoteVisuals = (): void => {
		if (this.currentControllerState != ControllerState.ItemPlacementVote) {
			this.logger.warn(
				`Trying to update ItemPlacementVote visuals while controller state is not ${ControllerState.ItemPlacementVote} (Current state: ${this.currentControllerState})`
			);
			return;
		}
		this.viewManager.positionReadyUnreadyButtonsForItemVote();
		this.viewManager.updateItemPlacementVoteVisuals(this.itemManagementData, this.app.imageURLCache);
		this.viewManager.setReadyButtonPressed(this.isReady);
		this.viewManager.setReadyButtonShowing(true);
	};

	public updateWriterTextInputVisuals = (): void => {
		if (this.currentControllerState != ControllerState.WaitingForInput) {
			this.logger.warn(
				`Trying to update WriterTextInput visuals while controller state is not ${ControllerState.WaitingForInput} (Current state: ${this.currentControllerState})`
			);
			return;
		}
		if (!this.isNonCharacterPlayer) {
			return;
		}
		this.viewManager.positionReadyUnreadyButtonsForWriterTextInput();
		this.viewManager.initialiseWriterTextInputVisuals(this.characterWritingData);
		this.viewManager.setReadyButtonShowing(true);
	};

	// Update character buttons to reflect the current state of character selections
	private updateCharacterSelectVisuals = (): void => {
		// Skip if not currently selecting characters.
		if (this.currentControllerState !== ControllerState.CharacterSelect) {
			this.logger.warn(
				`Trying to update CharacterSelect visuals while controller state is not ${ControllerState.CharacterSelect} (Current state: ${this.currentControllerState})`
			);
			return;
		}

		// All buttons default to the 'Unselected' state, then any selections are setup below
		let buttonStates: CharacterButtonState[] = [];
		for (let i = 0; i < GameSession.CharacterPlayerCount + GameSession.NonCharacterPlayerCount; i++) {
			buttonStates[i] = CharacterButtonState.Unselected;
		}

		let thisPlayerMadeSelection: boolean = false;

		// Setup the buttonStates array - character button visuals are determined by CharacterButtonState.
		//	A button with an index that matches this session's characterIndex should display as 'SelectedByThis', meaning
		//	the active player has selected the button. 'SelectedByOther' indicates a button that was selected by another player.
		for (let i = 0; i < this.selectedCharacterIndexes.length; i++) {
			const characterIndex: number = this.selectedCharacterIndexes[i];
			if (characterIndex >= 0 && characterIndex < buttonStates.length) {
				if (characterIndex === this.characterIndex) {
					buttonStates[characterIndex] = CharacterButtonState.SelectedByThis;
					thisPlayerMadeSelection = true;
				} else {
					buttonStates[characterIndex] = CharacterButtonState.SelectedByOther;
				}
			}
		}

		// Update button visuals to reflect buttonStates
		this.viewManager.updateCharacterSelectButtons(
			buttonStates,
			this.isNonCharacterPlayer,
			this.allNonCharactersSelected
		);
		// The player is 'ready' if they made a selection
		this.setReady(thisPlayerMadeSelection, false);
	};

	private resetForNewRound = (): void => {
		if (this.currentControllerState !== ControllerState.WaitingForInput) {
			this.logger.error('resetForNewRound should only be called when currentControllerState === WaitingForInput');
			return;
		}

		if (this.isActiveInView) {
			this.viewManager.setupTurnButtons(this.turnsPerRound);
		}

		const restoreTurnSet = this.cookieHelper.getTurnSetForSessionIndex(this.sessionIndex);
		const restoreTurnSelection = this.cookieHelper.getSelectedTurnForSessionIndex(this.sessionIndex);
		const validRestoreTurnSet: boolean =
			restoreTurnSet !== undefined && restoreTurnSet.turnSet.numTurns == this.turnsPerRound;
		if (this.turnSet === undefined && validRestoreTurnSet) {
			this.turnSet = restoreTurnSet!.turnSet;
			this.selectionWasMadeForTurn = restoreTurnSet!.playerSelectionsMade;
			this.selectATurnNumber(restoreTurnSelection);
		} else {
			this.turnSet = new TurnSet(this.turnsPerRound);
			this.selectionWasMadeForTurn = [];
			this.selectATurnNumber(0);
		}

		this.setReady(false, false);
	};

	// Called when page history changes as the user navigates (browser back button)
	private onWindowPopState = (event: Event): void => {
		if (event instanceof PopStateEvent) {
			this.onCancelOrBack();
		}
	};
}

export interface JoinGameParams {
	joinCode: string;
	playerName: string;
	forceJoinCode?: string;
}
